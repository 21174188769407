export default function passwordViewer() {
  let passwordViewer = document.querySelector('.teamup-password-viewer__button');

  if (passwordViewer) {
    passwordViewer.addEventListener("click", function (el) {

      const currentType = this.previousElementSibling.getAttribute('type');
      this.querySelector('span').remove();

      if (currentType === 'password') {
        this.previousElementSibling.setAttribute('type', 'text');
        this.innerHTML = '<span class="icon-eye"></span>';
      } else {
        this.previousElementSibling.setAttribute('type', 'password');
        this.innerHTML = '<span class="icon-eye-open"></span>';
      }
    });
  }
}