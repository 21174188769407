import $ from 'jquery';

// -------------------------------------------------------------------------------------------------
// Variables
// -------------------------------------------------------------------------------------------------

const baseClass = 'teamup--swiper';

// -------------------------------------------------------------------------------------------------
// Classes
// -------------------------------------------------------------------------------------------------

class QuestionSlider {
  constructor(el) {
    this.$swiper = el.querySelector('.swiper');
    this.$currentstep =  el.querySelector('.step-wrapper__current-step');
    this.$allSteps =  el.querySelector('.step-wrapper__all-steps');
    this.$inputRadio = el.querySelectorAll('input[type="radio"]');

    if (this.$inputRadio) {
      this.answerQuestion();
    }

  }

  answerQuestion() {
    const swiperElement = this.$swiper.swiper;
    const currentStep = this.$currentstep;
    const allSteps = this.$allSteps;

    allSteps.innerHTML = swiperElement.slides.length;

    for (let button of this.$inputRadio) {
      button.addEventListener("click", function (){
        setTimeout (function () {
          swiperElement.slideNext();
        },500)
      }, false);
    }

    swiperElement.on('slideChange', function () {
      currentStep.innerHTML = swiperElement.realIndex + 1;
    });
  }
}

// -------------------------------------------------------------------------------------------------
// Methods
// -------------------------------------------------------------------------------------------------

function init() {
  $(`.${baseClass}`).each((i, el) => new QuestionSlider(el));
}

// -------------------------------------------------------------------------------------------------
// Export
// -------------------------------------------------------------------------------------------------

export default {
  init,
};
