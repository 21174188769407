import $ from 'jquery';

// -------------------------------------------------------------------------------------------------
// Variables
// -------------------------------------------------------------------------------------------------

const baseClass = 'form-radio--img';

// -------------------------------------------------------------------------------------------------
// Classes
// -------------------------------------------------------------------------------------------------

class RadioImage {
  constructor(el) {
    this.el = el;
    this.selectImg(this.el);
  }

  selectImg(el) {
    el.addEventListener("click", function () {
      for (let item of el.closest('.form-radio-img-wrapper').querySelectorAll('.form-radio--img')) {
        item.closest('.form-radio-img-wrapper').classList.remove('form-radio-img-wrapper--is-checked')
        item.classList.remove('is-checked');
      }

      this.classList.add('is-checked');
      this.closest('.form-radio-img-wrapper').classList.add('form-radio-img-wrapper--is-checked')
    });
  }
}

// -------------------------------------------------------------------------------------------------
// Methods
// -------------------------------------------------------------------------------------------------

function init() {
  $(`.${baseClass}`).each((i, el) => new RadioImage(el));
}

// -------------------------------------------------------------------------------------------------
// Export
// -------------------------------------------------------------------------------------------------

export default {
  init,
};
