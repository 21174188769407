import $ from 'jquery';

import Swiper, {
  Autoplay as SwiperAutoplay,
  Controller as SwiperController,
  EffectFade as SwiperEffectFade,
  Navigation as SwiperNavigation,
  Pagination as SwiperPagination,
} from 'swiper';

Swiper.use([SwiperAutoplay, SwiperController, SwiperEffectFade, SwiperNavigation, SwiperPagination]);

// -------------------------------------------------------------------------------------------------
// Variables
// -------------------------------------------------------------------------------------------------

const baseClass = 'swiper-wrapper';
const slidableBreakpoint = '1200px';
let autoplayTime = 2000;

// -------------------------------------------------------------------------------------------------
// Classes
// -------------------------------------------------------------------------------------------------

class swiperSlider {
  constructor(el) {
    this._sliderActivated = false;

    this.el = el;
    this.swiper = null;

    this.$swiperContainer = $(el).find('.swiper');
    this.$swiperPrev = $(el).find('.swiper-button-prev');
    this.$swiperNext = $(el).find('.swiper-button-next');
    this.$swiperPagination = $(el).find('.swiper-pagination');
    this.$tabs = $(el).find(`.swiper-slide`);
    this.$swiperLoop = $(el).find('.swiper').attr('data-slider-loop');
    this.$swiperEffect = $(el).find('.swiper').attr('data-slider-effect');
    this.$swiperAutoplay = $(el).find('.swiper').attr('data-slider-autoplay');

    this.mediaMatcher = window.matchMedia(`(min-width: ${slidableBreakpoint})`);

    this.hasLoop = true;

    let isCategorySlide = false;

    this.initSlider('slide', this.$swiperLoop);
    
    if (this.$swiperAutoplay  > autoplayTime) {
      autoplayTime = this.$swiperAutoplay;
    }
  }

  initSlider(setEffect, isLoop) {
    if (this._sliderActivated || this.$tabs.length < 1) {
      return;
    }

    this.swiper = new Swiper(this.$swiperContainer[0], {
      slidesPerView: "auto",
      centeredSlides: true,
      loop: false,
      
      pagination: {
        el: this.$swiperPagination[0],
        type: "bullets",
        clickable: true,
      },

      autoplay: {
        disableOnInteraction: false,
        delay: autoplayTime,
      },

      navigation: {
        nextEl: this.$swiperNext[0],
        prevEl: this.$swiperPrev[0],
      },

      effect: setEffect,
      observer: true,
      observeParents: true,
      observeSlideChildren: true
    });

    this._sliderActivated = true;

    if (!this.$swiperAutoplay) {
      this.swiper.autoplay.stop();
    }
  }

  destroySlider() {
    if (this._sliderActivated === false || this.$tabs.length < 1) {
      return;
    }

    this.swiper.destroy();
    this.swiper = null;

    this._sliderActivated = false;
  }
}

// -------------------------------------------------------------------------------------------------
// Methods
// -------------------------------------------------------------------------------------------------

function init() {
  $(`.${baseClass}`).each((i, el) => new swiperSlider(el));
}

// -------------------------------------------------------------------------------------------------
// Export
// -------------------------------------------------------------------------------------------------

export default {
  init,
};
