import Shuffle from 'shufflejs'
import Select from "select-custom";

export default function shuffleJSFilter(filterClass, classContainer, elementClass) {
  if (filterClass != '') {
    const selects = [...document.querySelectorAll(filterClass)];

    selects.forEach((el) => {
      const select = new Select(el, {});
      select.init();
    });
  }

  if (document.querySelector(classContainer)) {
    const shuffleInstance = new Shuffle(document.querySelector(classContainer), {
      itemSelector: elementClass});

    const checkedInput = document.querySelector('input[checked]').getAttribute('data-filter')
    shuffleInstance.filter([checkedInput]);

    function selectFilter(item) {
      item.addEventListener('click', function() {
        const getAttribute = this.getAttribute('data-filter');
        const checkedInput = document.querySelector('input[checked]').getAttribute('data-filter')

        if (getAttribute === '*') {
          shuffleInstance.filter([checkedInput]);
        } else {
          shuffleInstance.filter([checkedInput,getAttribute]);
        }
      })
    }


    if (document.querySelector('.filter__select')) {
      document.querySelectorAll('.filter__select .custom-select__option').forEach(selectFilter);
    }

    if (document.querySelector('.filter__input')) {
      document.querySelectorAll('.filter__input input').forEach(selectFilter);
    }
  }
}