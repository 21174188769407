import $ from 'jquery';

// -------------------------------------------------------------------------------------------------
// Variables
// -------------------------------------------------------------------------------------------------

const baseClass = 'teamup-btn-filter-wrapper';
const resetButton = document.querySelector('.teamup-reset-filter');

// -------------------------------------------------------------------------------------------------
// Classes
// -------------------------------------------------------------------------------------------------

class ModalFilter {
  constructor(el) {
    this.$wrapper = el;
    this.$buttons = el.querySelectorAll('.btn');
    this.SelectionButton();
    this.ResetFilter();
  }

  SelectionButton() {
    for (let element of this.$buttons) {
      element.addEventListener("click", function () {
        let countSelectedButtons = 0;
        this.classList.toggle('btn--active');

        for (let item of document.querySelectorAll(`.${baseClass} .btn`)) {
          if (item.classList.contains('btn--active')) {
            countSelectedButtons++;
          }
        }

        if (countSelectedButtons === 0) {
          resetButton.classList.remove('is-visible');
        } else {
          resetButton.classList.add('is-visible');
        }
      }, false);
    }
  }

  ResetFilter() {
    resetButton.addEventListener("click", function () {
      for (let item of document.querySelectorAll(`.${baseClass} .btn`)) {
        item.classList.remove('btn--active');
        resetButton.classList.remove('is-visible');
      }
    });
  }
}

// -------------------------------------------------------------------------------------------------
// Methods
// -------------------------------------------------------------------------------------------------

function init() {
  $(`.${baseClass}`).each((i, el) => new ModalFilter(el));
}

// -------------------------------------------------------------------------------------------------
// Export
// -------------------------------------------------------------------------------------------------

export default {
  init,
};
