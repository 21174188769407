import Shuffle from 'shufflejs'

export default function adminCoachSearch() {
  const searchInput = document.querySelector('.teamup-search__input input');
  const searchNumber = document.querySelector('.teamup-search__results-headline');

  if (searchInput) {
    const shuffleInstance = new Shuffle(document.querySelector('.filter-wrapper-elements'), {
      itemSelector: '.teamup-search__results-entry'
    });

    searchInput.addEventListener("input",(e) => {
      const searchText = event.target.value.toLowerCase();

      shuffleInstance.filter((element, shuffle) => {
        const dataSearch = element.getAttribute('data-search').toLowerCase().trim();
        const currentTyp = document.querySelector('.filter .form-check-input:checked').getAttribute('data-filter');

        if (element.getAttribute('data-groups').includes(currentTyp)) {
          return dataSearch.includes(searchText);
        }
      });

      searchNumber.innerText = `${shuffleInstance.visibleItems} Einträge`;

      if (shuffleInstance.visibleItems == 0) {
        searchNumber.style.margin = 0;
      } else {
        searchNumber.style.removeProperty('margin');
      }
    });
  }
}