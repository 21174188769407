import Clipboard from "clipboard";
import $ from 'jquery';

export default function clipboard() {
  const clipboardBtn = document.querySelector('.teamup-clipboard');

  if (clipboardBtn) {
    let clipboard = new Clipboard('.teamup-clipboard .btn');

    clipboard.on('success', function (e) {
      const btnCopy = document.querySelector('.teamup-clipboard .teamup-clipboard__btn-text');
      btnCopy.parentNode.classList.add('btn--success');
      btnCopy.innerText = 'Der Link erfolgreich in die Zwischenablage kopiert!';

      setTimeout(() => {
        btnCopy.parentNode.classList.remove('btn--success');
        btnCopy.innerText = btnCopy.parentNode.getAttribute('data-clipboard-text');
      }, 2000);

      e.clearSelection();
    });

    clipboard.on('error', function (e) {
      const btnCopy = document.querySelector('.teamup-clipboard .teamup-clipboard__btn-text');
      btnCopy.parentNode.classList.add('btn--error');
      btnCopy.innerText = 'Der Link konnte nicht in die Zwischenablage kopiert werden!';

      setTimeout(() => {
        btnCopy.parentNode.classList.remove('btn--error');
        btnCopy.innerText = btnCopy.parentNode.getAttribute('data-clipboard-text');
      }, 2000);
    });
  }
}