import $ from 'jquery';
import 'bootstrap';
import shuffleJSFilter from './components/shuffleJSFilter';
import swiperslider from './components/swiper';
import QuestionSlider from "./modules/question-slider";
import RadioImage from "./components/radio-image";
import adminCoachSearch from "./components/_adminCoachSearch";
import ModalFilter from "./components/modal-filter";
import clipboard from "./components/_clipboard";
import passwordViewer from "./components/_password-viewer";

$(document).ready(function () {
  // shuffleJSFilter('.js-select', '.cards-container', '.card');
  swiperslider.init();
  QuestionSlider.init();
  RadioImage.init();

  adminCoachSearch();
  clipboard();
  passwordViewer();
  ModalFilter.init();
});

window.addEventListener("load", (event) => {
  shuffleJSFilter('','.filter-wrapper-elements', '.filter-element');
});
